<script setup lang="ts">
import appConfig from "~/app.config";

const { title, subTitle, imgLink, sourceOrigin, type, playstore, applestore } =
  defineProps<{
    title: string;
    subTitle: string;
    color: string;
    imgLink: string;
    playstore: string;
    applestore: string;
    sourceOrigin: string;
    type: string;
  }>();

const playStoreUrl = computed(() => {
  return `${playstore}&${appConfig.utm.source}${sourceOrigin}${appConfig.utm.campaign}${appConfig.utm[type].download_app}`;
});

const appleStoreUrl = computed(() => {
  return `${applestore}?${appConfig.utm.source}${sourceOrigin}${appConfig.utm.campaign}${appConfig.utm[type].download_app}`;
});
</script>

<template>
  <div class="download-app">
    <span class="download-app--title">
      {{ title }}
    </span>
    <NuxtImg
      class="download-app--img"
      :src="imgLink"
      alt="telechargement de l'app Extracadabra"
      loading="lazy"
      title="Telechargement de l'app Extracadabra"
    />
    <span class="download-app--subtitle">{{ subTitle }}</span>
    <div class="store-buttons">
      <NuxtLink :to="playStoreUrl" target="_blank" rel="nofollow">
        <NuxtImg
          class="store-buttons--icon"
          src="/img/logo-playstore.webp"
          alt="Google play"
          loading="lazy"
          width="110"
          title="Logo Google play"
        />
      </NuxtLink>
      <NuxtLink :to="appleStoreUrl" target="_blank" rel="nofollow">
        <NuxtImg
          class="store-buttons--icon"
          src="/img/logo-appstore.webp"
          alt="Apple Store"
          loading="lazy"
          width="110"
          title="Logo Apple Store"
        />
      </NuxtLink>
    </div>
    <div class="spacing">
      <NuxtImg
        class="extracadabra-logo"
        src="/img/logo_extracadabra_full.svg"
        alt="logo extracadabra"
        loading="lazy"
        title="Logo Extracadabra"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.download-app {
  padding: 0.9rem;
  background-color: v-bind(color);
  color: $white;
  text-align: center;
  &--title {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 800;
    line-height: 23px;
    font-weight: bold;
    font-size: 16px;
    display: flex;
  }
  &--img {
    padding-bottom: 1rem;
    max-width: 90%;
  }

  &--subtitle {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }
  .spacing {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .store-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .extracadabra-logo {
    width: 140px;
    padding-bottom: 1rem;
  }
}
</style>
